import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";

import { webSocketConnect } from "@astrocentro-webapp/astrocentro/modules/commons/utils/webSocketConnect";

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://42e3f7e5997849dba5b6bc69a7e1d9ac@o457859.ingest.sentry.io/4505240168431616",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.VITE_ENVIRONMENT == "production",
});

app.use(createPinia());
app.use(router);

webSocketConnect();

app.mount("#app");
