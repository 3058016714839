import { WebSocketAdapter } from "@astrocentro-webapp/commons/modules/commons/adapters/WebSocketAdapter";
import { ChatEvents } from "@astrocentro-webapp/astrocentro/modules/chat/events/ChatEvents";

/**
 * Create a websocket connection
 */
export const webSocketConnect = async () => {
  if (localStorage.accessToken) {
    const webSocketAdapter = new WebSocketAdapter();
    const chatEvents = new ChatEvents();
    window.webSocketAdapter = webSocketAdapter.getInstance(
      localStorage.accessToken
    );

    chatEvents.subscribe();
  }
};
