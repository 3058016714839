import { ConsultationChannelEvents } from "@astrocentro-webapp/astrocentro/modules/chat/events/ConsultationChannelEvents";

export const emitMessageSentEventToSocketListener = ({
  consultationId,
  ...message
}) => {
  const consultationChannelEvents = new ConsultationChannelEvents(
    consultationId
  );
  consultationChannelEvents.trigger("client-chat-message-received", { ...message, consultationId });
};
