import { createRouter, createWebHistory } from "vue-router";

const ChatView = () =>
  import("@astrocentro-webapp/astrocentro/modules/chat/views/ChatView.vue");
const SummaryView = () =>
  import("@astrocentro-webapp/astrocentro/modules/chat/views/SummaryView.vue");
const WaitingView = () =>
  import("@astrocentro-webapp/astrocentro/modules/chat/views/WaitingView.vue");
const SellersListView = () =>
  import(
    "@astrocentro-webapp/astrocentro/modules/sellers/views/SellersListView.vue"
  );
const WizardView = () =>
  import("@astrocentro-webapp/astrocentro/modules/match/views/WizardView.vue");
const CallConsultationView = () =>
  import(
    "@astrocentro-webapp/astrocentro/modules/call/views/ConsultationView.vue"
  );
const CallConsultationWaitingRomView = () =>
  import(
    "@astrocentro-webapp/astrocentro/modules/call/views/ConsultationWaitingRoomView.vue"
  );
const CallSummaryView = () =>
  import(
    "@astrocentro-webapp/astrocentro/modules/call/views/ConsultationSummaryView.vue"
  );

const CallConsultationQueueRomView = () =>
    import(
        "@astrocentro-webapp/astrocentro/modules/call/views/ConsultationQueueRoomView.vue"
        );

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/consultations/chat/:consultationId",
      name: "consultations-id",
      component: ChatView,
    },
    {
      path: "/consultations/chat/:consultationId/summary",
      name: "summary-id",
      component: SummaryView,
    },
    {
      path: "/consultations/chat/:consultationId/waiting",
      name: "waiting",
      component: WaitingView,
    },
    {
      path: "/specialties/:specialty?",
      name: "specialties",
      component: SellersListView,
    },
    {
      path: "/caminhos-do-destino",
      name: "caminhos-destino",
      component: WizardView,
    },
    {
      path: "/consultations/call/:consultationId",
      name: "call-consultation-id",
      component: CallConsultationView,
    },
    {
      path: "/consultations/call/:consultationId/waiting",
      name: "call-consultation-waiting",
      component: CallConsultationWaitingRomView,
    },
    {
      path: "/consultations/call/:consultationId/summary",
      name: "call-summary-id",
      component: CallSummaryView,
    },
    {
      path: "/consultations/call/:queueId/queue",
      name: "call-consultation-queue",
      component: CallConsultationQueueRomView,
    },
  ],
});

export default router;
