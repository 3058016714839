import { useConversationsStore } from "@astrocentro-webapp/astrocentro/stores/conversations";

export const storeMessageInConversationStoreListener = async ({
  chatRoomId,
  ...message
}) => {
  const conversationsStore = useConversationsStore();

  return conversationsStore.$patch(async () => {
    const isStored = conversationsStore.getMessageById(chatRoomId, message.id);

    if (!isStored) {
      return conversationsStore.addMessage(chatRoomId, {
        ...message,
        hasError: !window.navigator.onLine,
      });
    }
  });
};
