import { ChannelEvents } from "@astrocentro-webapp/commons/modules/commons/events/ChannelEvents";
import { storeMessageInConversationStoreListener } from "@astrocentro-webapp/astrocentro/modules/chat/listeners/storeMessageInConversationStoreListener";
import { updateMessageWasReadListener } from "@astrocentro-webapp/astrocentro/modules/chat/listeners/updateMessageWasReadListener";
import { updateConsultationStoreListener } from "@astrocentro-webapp/astrocentro/modules/chat/listeners/updateConsultationStoreListener";
import { storeMessageInExternalDatabaseListener } from "@astrocentro-webapp/commons/modules/chat/listeners/storeMessageInExternalDatabaseListener";
import { dispatchCustomEvent } from "@astrocentro-webapp/commons/modules/chat/listeners/dispatchCustomEvent";
import { SetChatChannelStatusService } from "@astrocentro-webapp/commons/modules/chat/services/SetChatChannelStatusService";

export class ConsultationChannelEvents extends ChannelEvents {
  #setChatChannelStatusService;

  constructor(consultationId) {
    super(`presence-consultation-${consultationId}`);
    this.#setChatChannelStatusService = new SetChatChannelStatusService(
      this.channelName
    );
  }

  bindListeners() {
    const channel = window.webSocketAdapter.subscribe(this.channelName);
    channel.unbind();

    channel
      .bind("chat-message-received", (event) => {
        storeMessageInConversationStoreListener(event);
      })
      .bind("client-chat-message-received", (event) => {
        dispatchCustomEvent("new-message-received");
        dispatchCustomEvent("client-is-typing");
        this.trigger("client-chat-message-read", event);
        storeMessageInConversationStoreListener(event);
      })
      .bind("client-chat-message-read", (message) => {
        updateMessageWasReadListener(message);
        storeMessageInExternalDatabaseListener(message);
      })
      .bind("client-is-typing", () => {
        dispatchCustomEvent("client-is-typing");
      })
      .bind("client-is-not-typing", () => {
        dispatchCustomEvent("client-is-not-typing");
      })
      .bind("chat-consultation-accepted", (event) => {
        updateConsultationStoreListener(event);
      })
      .bind("chat-consultation-timed-out", (event) => {
        updateConsultationStoreListener(event);
      })
      .bind("chat-consultation-refused", (event) => {
        updateConsultationStoreListener(event);
      })
      .bind("consultation-updated", (event) => {
        updateConsultationStoreListener(event);
      })
      .bind("chat-consultation-finished", (event) => {
        updateConsultationStoreListener(event);
      })
      .bind("pusher:member_added", () => {
        this.#setChatChannelStatusService.perform();
      })
      .bind("pusher:member_removed", () => {
        this.#setChatChannelStatusService.perform();
      })
      .bind("pusher:subscription_succeeded", () => {
        this.#setChatChannelStatusService.perform();
      });
  }
}
