import { DateHelper } from "@astrocentro-webapp/commons/modules/commons/helpers/DateHelper";
import { useConversationsStore } from "@astrocentro-webapp/astrocentro/stores/conversations";

export const updateMessageHasErrorListener = async ({ chatRoomId, id }) => {
  const dateHelper = new DateHelper();
  const currentDate = dateHelper.format(null, "yyyy-MM-dd");
  const conversationsStore = useConversationsStore();
  const fiveSecondsTimeout = 1 * 1000 * 5;

  // TODO: use getMessageById and updateMessageById functions from store instead
  setTimeout(async () => {
    conversationsStore.$patch(async (state) => {
      state.conversations = state.conversations.map((conversation) => {
        if (
          conversation.chatRoomId === chatRoomId &&
          conversation.date === currentDate
        ) {
          conversation.messages.forEach((message) => {
            if (message.id === id && !message.isRead) {
              message.hasError = true;
            }
          });

          return conversation;
        }

        return conversation;
      });
    });
  }, fiveSecondsTimeout);
};
