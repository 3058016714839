import { useConsultationStore } from "@astrocentro-webapp/astrocentro/stores/consultation";

export const updateConsultationStoreListener = (event) => {
  const consultationStore = useConsultationStore();

  // TODO: create a function in store to update its state
  consultationStore.consultation = {
    ...consultationStore.consultation,
    ...event.consultation,
  };
};
