import { ref } from "vue";
import { defineStore } from "pinia";

import { DateHelper } from "@astrocentro-webapp/sellers/modules/commons/helpers/DateHelper";

export const useConversationsStore = defineStore("conversations", () => {
  const conversations = ref([]);
  const dateHelper = new DateHelper();

  /**
   * Helper function to get current conversation by chat room id.
   *
   * @param {number} chatRoomId
   *
   * @returns {(object | null)}
   */
  const getById = (chatRoomId) => {
    const currentDate = dateHelper.format(null, "yyyy-MM-dd");
    const conversation = conversations.value.find(
      (conversation) =>
        conversation.chatRoomId === chatRoomId &&
        conversation.date === currentDate
    );

    return conversation ? conversation : null;
  };

  /**
   * Get message related to chat conversation.
   *
   * @param {number} chatRoomId
   * @param {number} id
   *
   * @returns {(object | null)}
   */
  const getMessageById = (chatRoomId, id) => {
    const conversation = getById(chatRoomId);

    if (conversation) {
      const message = conversation.messages.find(
        (message) => message.id === id
      );

      return message ? message : null;
    }

    return null;
  };

  /**
   * Add message to chat conversation.
   *
   * @param {number} chatRoomId
   * @param {object} payload
   */
  const addMessage = (chatRoomId, payload) => {
    const conversation = getById(chatRoomId);

    if (conversation) {
      conversation.messages.push(payload);
    }
  };

  /**
   * Update message related to chat conversation.
   *
   * @param {number} chatRoomId
   * @param {number} id
   * @param {object} payload
   */
  const updateMessageById = (chatRoomId, id, payload) => {
    const message = getMessageById(chatRoomId, id);

    if (message) {
      Object.assign(message, payload);
    }
  };

  return { conversations, getMessageById, addMessage, updateMessageById };
});
